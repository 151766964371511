.jsbgphoto
{
background-image: url("../images/js2.jpg");
background-repeat: no-repeat;
background-size: cover ;
overlay: rgba(255, 0, 0, 0.39);
}

.dropdown button
{
    padding: 5px 40px !important;
    width:100% !important;
    background-color: unset !important;
    border: 1px solid #480f61;
    color: #480f61;
}
.dropdown button:hover
{
    background-color: #a741dc !important;
    color: white !important;
}
.jobcradhovereffect:hover
{
    box-shadow: 0px 0px 30px 3px #e7d5f0 !important;
}